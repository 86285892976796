export default [
  {
    name: 'unique_id',
    displayName: 'Unique ID',
    type: 'string',
    isCheckbox: true,
  },
  {
    name: 'home_owner',
    displayName: 'Home Owner',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'current_status',
    displayName: 'Current Status',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'dealer_code',
    displayName: 'Dealer Code',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'sys_size',
    displayName: 'Sys Size',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'contract',
    displayName: 'Contract $',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'other_adders',
    displayName: 'Other Adders',
    type: 'string',
    isCheckbox: false,
  },
  
  {
    name: 'rep_1',
    displayName: 'Rep 1',
    type: 'string',
    isCheckbox: false,
  },
  { name: 'rep_2', displayName: 'Rep 2', type: 'string', isCheckbox: false },
  {
    name: 'setter',
    displayName: 'Setter',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'st',
    displayName: 'State',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'contract_date',
    displayName: 'Contract Date',
    type: 'date',
    isCheckbox: false,
  },
  {
    name: 'loan_fee',
    displayName: 'Loan Fee',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'net_epc',
    displayName: 'NET EPC',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'credit', displayName: 'Credit', type: 'string', isCheckbox: false },
  {
    name: 'draw_amt',
    displayName: 'Draw Amt',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'rl', displayName: 'RL', type: 'number', isCheckbox: false },
  {
    name: 'type',
    displayName: 'Type',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'today',
    displayName: 'Today',
    type: 'date',
    isCheckbox: false,
  },
  { name: 'amount', displayName: 'Amount', type: 'number', isCheckbox: false },
  { name: 'epc', displayName: 'EPC', type: 'number', isCheckbox: false },
  {
    name: 'amt_paid',
    displayName: 'Amount Paid',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'balance',
    displayName: 'Balance',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'help', displayName: 'Help', type: 'string', isCheckbox: false },
];
